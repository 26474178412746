import React from "react";
import { StyledTooltip } from '../tooltip';
import styles from "../../styles/contactSocial.module.scss";
import { FaGithub } from "@react-icons/all-files/fa/FaGithub";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaMedium } from "@react-icons/all-files/fa/FaMedium";
import { CgIndieHackers } from "@react-icons/all-files/cg/CgIndieHackers";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import Icon from "@material-ui/core/Icon";

export default function Social() {
  return (
    <div className={ styles.social }>
      <StyledTooltip title="Github">
        <a href="https://github.com/asitwala"
          target="_blank"
          rel="noopener noreferrer">
          <Icon color="primary" fontSize="small">
            <FaGithub />
          </Icon>
        </a>
      </StyledTooltip>

      <StyledTooltip title="LinkedIn">
        <a href="https://www.linkedin.com/in/amysitwala/"
          target="_blank"
          rel="noopener noreferrer">
          <Icon color="primary" fontSize="small">
            <FaLinkedin />
          </Icon>
        </a>
      </StyledTooltip>

      <StyledTooltip title="Twitter">
        <a href="https://twitter.com/amy_sitwala"
          target="_blank"
          rel="noopener noreferrer">
          <Icon color="primary" fontSize="small">
            <FaTwitter />
          </Icon>
        </a>
      </StyledTooltip>

      <StyledTooltip title="Indie Hackers">
        <a href="https://www.indiehackers.com/asitwala/history"
          target="_blank"
          rel="noopener noreferrer">
          <Icon color="primary" fontSize="small">
            <CgIndieHackers />
          </Icon>
        </a>
      </StyledTooltip>

      <StyledTooltip title="Medium">
        <a href="https://medium.com/@asitwala"
          target="_blank"
          rel="noopener noreferrer">
          <Icon color="primary" fontSize="small">
            <FaMedium />
          </Icon>
        </a>
      </StyledTooltip>

      <StyledTooltip title="Instagram">
        <a href="https://www.instagram.com/awishbonegirl/"
          target="_blank"
          rel="noopener noreferrer">
          <Icon color="primary" fontSize="small">
            <FaInstagram />
          </Icon>
        </a>
      </StyledTooltip>
    </div>
  );
};
