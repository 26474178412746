import React from "react";
import { Link } from "gatsby";
import Social from "../components/contact/social";

import { Image } from "react-image-and-background-image-fade";
import { IconContext } from "@react-icons/all-files";
import { MdBusinessCenter } from "@react-icons/all-files/md/MdBusinessCenter";
import { CgComponents } from "@react-icons/all-files/cg/CgComponents";
import { GoDatabase } from "@react-icons/all-files/go/GoDatabase";

import { FaPaintBrush } from "@react-icons/all-files/fa/FaPaintBrush";
import { FaLeaf } from "@react-icons/all-files/fa/FaLeaf";
import { FaGamepad } from "@react-icons/all-files/fa/FaGamepad";

import styles from "../styles/about.module.scss";

export default function About() {
  return (
    <div className={ styles.container }>
      <h1 className={ `header` }>About</h1>

      {/* Intro */}
      <div className={ styles.intro }>

        {/* Intro -- Left */}
        <div className={ styles.intro__left }>
          <p>
            Hi there, and thanks for checking out my personal site!
          </p>

          <p>
            I'm <b>Amy</b>, an entrepreneurial-spirited software engineer who looks for ways
            to combine business, design, and technology — three fields that have
            interested me for over 10 years. I ended up falling in love with <b className={ styles.highlight }>full-stack development</b> because
            it lets me interact with at least one of those fields daily
            in the following ways:
          </p>

          {/* WORK GRID */}
          <div className={ styles.work__grid }>
            <div className={ styles.work__label }>
              <IconContext.Provider value={{ size: '18px', color: '#FF9A23' }}>
                <MdBusinessCenter className={ `icon__left icon__2px__up`} />
              </IconContext.Provider>
              
              Business
            </div>
            <div>
              Understanding user requirements, scheduling user
              feature requests, handling customer support via Intercom, and implementing
              basic marketing tactics (measuring KPIs, crafting surveys, copywriting, etc.)
            </div>

            <div className={ styles.work__line }></div>

            <div className={ styles.work__label }>
              <IconContext.Provider value={{ size: '18px', color: '#FF9A23' }}>
                <CgComponents className={ `icon__left icon__2px__up`} />
              </IconContext.Provider>
              Design
            </div>
            <div>
              Prototyping and building user interfaces from scratch
              in Figma and learning design techniques from professional designers on
              Twitter and Dribbble
            </div>

            <div className={ styles.work__line }></div>

            <div className={ styles.work__label }>
              <IconContext.Provider value={{ size: '18px', color: '#FF9A23' }}>
                <GoDatabase className={ `icon__left icon__2px__up`} />
              </IconContext.Provider>
              Technology
            </div>
            <div>
              Architecting applications (databases,
              microservices, etc.), setting up or interacting with APIs, coding up
              interfaces I've designed or have been given, and actively diving deeper
              into concepts via interactive Udemy and A Cloud Guru courses
            </div>
          </div>
        </div>
        
        {/* Intro -- Right */}
        <Image
          alt="Amy Sitwala About"
          src={ `${process.env.GATSBY_ASSETS_URL}/about.jpg` }
          wrapperClassName={ styles.intro__right }
          width="318px"
          height="351px"
          lazyLoad
        />
      </div>

      <h2 className={ styles.header }>
        Here's what I've been up to, career-wise
      </h2>

      <p>
        These days, I work remotely as an Engineer at <a
          href="https://www.slalom.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="link">
          Slalom
        </a>,
        where my team and I create reusable, cloud assets in AWS to help technical consultants kickstart new projects.
        You'll also find us "eating our own dog food" and leveraging our resusable assets to build internal web applications
        for centralizing data and common consulting workflows.
      </p>

      <p>
        Before joining Slalom, I founded and worked full-time on <a
          className={ `link` }
          href="https://profitkit.io/"
          rel="noreferrer noopener"
          target="_blank"
          >
          ProfitKit
        </a> with my co-founder and life-partner Matt.
        Starting my own business has been incredibly daunting at times, but
        simultaneously, it has allowed me to make other people's lives easier.
        I've also done quite a bit of introspection along the way and have learned:
      </p>

      <ul className={ styles.goals__list }>
        <li>
          That my long-term career goal is to become a <b className={ styles.highlight }>software architect</b> equipped 
          with the skills to future-proof and scale up applications
          with minimal friction
        </li>
        <li>
          How to be more resilient and bounce back when challenges arise
        </li>
        <li>
          That my work does not 100% define me and that it's important
          to take time for myself at least 1-2 days a week
        </li>
      </ul>
      
      <p>
        If you're interested, I wrote more about <a
          className={ `link` }
          href="https://bigstories.medium.com/its-a-tough-road-and-i-faced-a-lot-of-judgement-for-being-on-a-less-traditional-path-92b0172966e3"
          rel="noreferrer noopener"
          target="_blank"
          >
        what the journey has been like so far</a>!
      </p>

      <p>
        I've also worked part-time as a <b className={ styles.highlight }>technical consultant and freelance developer</b> for
        small businesses. This work often involved integrating Stripe, and it's actually what initially led me to
        make the leap and start ProfitKit! Some of my favorite professional memories
        have come from freelance work — it's been so rewarding to help bring other
        people's visions to life and see their joy throughout the process!
      </p>

      <p>
        Prior to that, I worked as an <b className={ styles.highlight }>Associate Software Engineer at Morningstar</b>,
        the independent investment research firm — NOT Morningstar Farms,
        the food company most people I tell confuse it with <span role="img" aria-label="grinning face with squinting eyes and sweat drop">😅</span> — 
        shortly after graduating from <b className={ styles.highlight }>The University of Chicago</b> with degrees
        in Economics and Computer Science in 2017.
      </p>

      <p>
        I'm immensely grateful for my time at Morningstar, as I got to work with
        a close-knit, talented team of individuals that actively mentored me. I
        learned so much more about the agile methodology, project management, QA,
        visual design, UX design, and full-stack development just by talking and
        collaborating with my team members. Morningstar also set the stage for my
        interest in software architecture and helped me get AWS certified.
      </p>

      <p>
        The academic rigor of UChicago forced me to learn better time management
        skills and develop a solid work ethic. Being one of the few people in my
        Computer Science classes who had never written a line of code before college,
        I often found myself experiencing imposter syndrome. While to this day,
        I still struggle with it at times, I've discovered that it's <b>amazing what
        you can accomplish when you focus on yourself and don't compare yourself
        to others</b>.
      </p>

      <p>
        If you're interested in working with me or just want to say hi, <Link to="/contact" className={ `link` }>feel
        free to shoot me a message</Link>!
      </p>

      <h2 className={ styles.header }>
        Outside of work, I enjoy 
      </h2>

      <div className={ styles.hobby }>
        <div className={ styles.hobby__icon }>
          <IconContext.Provider value={{ size: '18px', color: '#FF9A23' }}>
            <FaPaintBrush />
          </IconContext.Provider>
        </div>

        <div>
          <div className={ styles.hobby__label }>
            Art
          </div>
          I dabble in both traditional (pen, pencil, etc.) and digital
          (graphic tablet) art. I usually draw portraits, characters, still lifes,
          and landscapes. If you’re interested, you can find samples of my art on my <a
          className={ `link` }
          href="https://asitwala.herokuapp.com/gallery/"
          rel="noreferrer noopener"
          target="_blank"
          >
          old website
          </a> <i>(please give it some time to load)</i> and <a
          className={ `link` }
          href="https://www.instagram.com/awishbonegirl/"
          rel="noreferrer noopener"
          target="_blank"
          >
          Instagram
          </a>!
        </div>
      </div>

      <div className={ styles.hobby }>
        <div className={ styles.hobby__icon }>
          <IconContext.Provider value={{ size: '18px', color: '#FF9A23' }}>
            <FaLeaf />
          </IconContext.Provider>
        </div>

        <div>
          <div className={ styles.hobby__label }>
            Exercise &amp; Nature
          </div>
          Exercising and being outdoors have been
          a lifelong part of my identity and have done wonders for both my physical
          and mental health. On a daily basis, I either walk, jog, kickbox, or do
          calisthenics. Throughout the year, I waterski, wakeboard, and hike. My
          current favorite hiking spots are <b>Bryce Canyon National
          Park</b> in Utah, as well as <b>Pine Creek Canyon</b> (Red Rock Conservation
          Area) and <b>Mount Charleston</b> in Nevada <i>(as seen in the picture above)</i>!
        </div>
      </div>
        

      <div className={ styles.hobby }>
        <div className={ styles.hobby__icon }>
          <IconContext.Provider value={{ size: '18px', color: '#FF9A23' }}>
            <FaGamepad />
          </IconContext.Provider>
        </div>
        <div>
          <div className={ styles.hobby__label }>
            Casual Gaming
          </div>
          I like gaming from time to time, and I've also found
          it to be a fun way to bond with family, friends, and even co-workers!
          Some of my favorite games throughout the years have been <b>Horizon Zero Dawn &amp; Forbidden West</b>, <b>Starcraft I &amp; II</b> (
          <Image
            wrapperClassName={`${styles.pylon}`}
            src={ `${process.env.GATSBY_ASSETS_URL}/pylon.png` }
            width="18px"
            height="18px"
            alt="Protoss Pylon"
            lazyLoad
            /> Protoss forever), Frostpunk, Valheim, Gears 5, Diablo III, Super Smash Bros, and
          Super Mario Bros.
        </div>
      </div>

      <div className={ styles.social }>
        <Social />
      </div>
    </div>
  );
};